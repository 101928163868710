<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pb-10 pb-2">Users screen</div>
      <v-data-table
        :headers="headers"
        :items="list"
        :options.sync="options"
        :server-items-length="rows"
        :loading="isloadingPagination"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, -1],
          'items-per-page-all-text': 'Todos',
          'items-per-page-text': 'Filas por página',
        }"
        :items-per-page="filtersData.itemsPerPage"
        class="elevation-1"
        locale="es"
      >
        <template v-slot:header.nombre="{ header }">
          <b class="custom-table-header"> Nombre </b>
        </template>
        <template v-slot:header.apellido="{ header }">
          <b class="custom-table-header"> Apellido </b>
        </template>
        <template v-slot:header.estado="{ header }">
          <b class="custom-table-header"> Estado </b>
        </template>
        <template v-slot:item.estado="{ item }">
          <div class="text-center">
            <b-badge variant="primary">Iniciado</b-badge>
          </div>
        </template>
        <template v-slot:item.detalles="{ item }">
          <div class="text-center">
            <span
              class="svg-icon svg-icon-3x cursor-pointer"
              @click="goDetail(item)"
            >
              <inline-svg src="media/svg/icons/Design/ZoomMinus.svg" />
            </span>
          </div>
        </template>
        <template slot="no-data"> Ningún dato para mostrar </template>
      </v-data-table>
    </div>
  </b-overlay>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  name: "Users",
  data() {
    return {
      currentPage: 1,
      isLoading: false,
      isloadingPagination: true,
      options: {},
      headers: [
        { text: "Nombre", value: "nombre", sortable: false },
        { text: "Apellido", value: "apellido", sortable: false },
        {
          text: "Estado",
          value: "estado",
          align: "start",
          sortable: false,
        },
        {
          text: "Detalles",
          value: "detalles",
          align: "start",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Usuarios", route: "#" },
      { title: "Listado de usuarios" },
    ]);
  },
  computed: {
    totalRows() {
      return 0;
    },
    list() {
      return [];
    },
  },
};
</script>

<style scoped></style>
